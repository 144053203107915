'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:UserPasswordResetCtrl

 # @description

###
class UserPasswordResetCtrl
  ### @ngInject ###
  constructor: ($stateParams, Restangular) ->
    @ctrlName = 'UserPasswordResetCtrl'

    @entity =
      token: $stateParams.token
      email: null

    @errorMessage = null

    @request = () =>
      @errorMessage = null

      Restangular.all 'open/users/password/reset'
        .customPOST
          email: @entity.email
        .then (result) =>
          @entity = result
          @errorMessage = null
        , () =>
          @errorMessage = 'app.errors.could-not-reset-password'

    @confirm = () =>
      @errorMessage = null

      Restangular.all 'open/users/password/reset/confirm'
        .customPOST
          token: @entity.token
          plainPassword: @entity.plainPassword
        .then (result) =>
          @entity = result
          @errorMessage = null
        , () =>
          @errorMessage = 'app.errors.could-not-reset-password'

angular
  .module('mundoAuthentication')
  .controller 'UserPasswordResetCtrl', UserPasswordResetCtrl
